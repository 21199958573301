import Vue from 'vue'
import VueRouter from 'vue-router'
import { mapActions } from 'vuex'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  keyName: 'page',
})

const router = new VueRouter({
  mode: 'history',
  methods: {
    ...mapActions(['login']),
  },
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: 'common',
      component: () => import('./layouts/nomenu.vue'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@views/common/landing'),
        },
        {
          path: '/openapp/:locationId',
          name: 'openapp',
          component: () => import('@views/common/landing'),
          meta: { authRequired: false, pageTitle: 'Setup Location' },
        },
        {
          path: '/openapp/:locationId/default-procedure',
          name: 'view-default-procedure-landing',
          component: () => import('@views/common/landing'),
          meta: { authRequired: false, pageTitle: 'Emergency Procedures' },
        },
        {
          path: '/404',
          name: '404',
          component: () => import('@views/common/error-404'),
          meta: { authRequired: false },
        },
        {
          path: '/500',
          name: '500',
          component: () => import('@views/common/error-500'),
          meta: { authRequired: false },
        },
        {
          path: '/:locationId/RegisterTenant',
          name: 'register-tenant',
          component: () => import('@views/tenant/create'),
          meta: { authRequired: false, pageTitle: 'Register Tenant' },
        },
        {
          path: '/openapp/:locationId/respond-messaging/:incidentId',
          name: 'view-messages-respond=incident',
          component: () => import('@views/messages/respond'),
          meta: { authRequired: false, pageTitle: 'Need Help' },
        },
        {
          path: '*',
          redirect: '404',
        },
      ],
    },
    {
      path: '/',
      redirect: '/login',
      component: () => import('./layouts/main.vue'),
      children: [        
        {
          path: '/openapp/:locationId/view-procedure',
          name: 'view-default-procedure',
          component: () => import('@views/Procedures/default-procedure'),
          meta: { authRequired: false, pageTitle: 'Emergency Procedures' },
        },
        {
          path: '/openapp/:locationId/default-messaging',
          name: 'view-messages',
          component: () => import('@views/messages/index'),
          meta: { authRequired: false, pageTitle: 'Need Help' },
        },
        {
          path: '/openapp/:locationId/default-messaging/:incidentId',
          name: 'view-messages-incident',
          component: () => import('@views/messages/index'),
          meta: { authRequired: false, pageTitle: 'Need Help' },
        },
        {
          path: '/:locationId/designation/:designationId/selectedCategory/:categoryId',
          name: 'view-procedure-selected-category',
          component: () => import('@views/Procedures/index'),
          meta: { authRequired: false, pageTitle: 'Emergency Procedures' },
        },
        {
          path: '/:locationId/designation/:designationId/category/:categoryId',
          name: 'view-procedure',
          component: () => import('@views/Procedures/default-procedure'),
          meta: { authRequired: false, pageTitle: 'Emergency Procedures' },
        },
        {
          path: '/:locationId/designation/:designationId',
          name: 'view-procedure-category',
          component: () => import('@views/Procedures/default-procedure'),
          meta: { authRequired: false, pageTitle: 'Emergency Procedures' },
        },
        {
          path: '/openapp/:locationId/default-assemblyarea',
          name: 'view-assembly',
          component: () => import('@views/Procedures/assemblyArea'),
          meta: { authRequired: false, pageTitle: 'Assembly Area' },
        },
      ],
    },
  ],
})

// router.afterEach((routeTo, routeFrom) => {
//   NProgress.done()
// })

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  // if (routeFrom.name !== null) {
  //   NProgress.start()
  // }

  if (routeTo.fullPath === routeFrom.fullPath && routeTo.fullPath !== '/') {
    return next(false)
  }

  const authRequired = routeTo.matched.some((route) => route.meta.requireAuth)
  if (!authRequired) return next()

  return next()
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args.length) {
              // if (routeFrom.name === args[0].name) {
              //   NProgress.done()
              // }
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          resolve()
        }
      })
    }
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

export default router
