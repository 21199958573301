import router from '../router'

const actions = {
  async completeLogin({ commit }, user) {
    if (user !== undefined || user !== null) {
      commit('updateUser', user)
      commit('updateIsAuthenticated', true)
    } else {
      router.push('/login').catch((err) => {
      })
    }
  },

  setIsProgressing({ commit }, value) {
    commit('IsProgressing', value)
  },

  setLoggedInUser({ commit }, value) {
    commit('updateUser', value)
  },

  setLoggedInLocation({ commit }, value) {
    commit('updateLocation', value)
  },

  setGlobalMessage({ commit }, value) {
    commit('globalMessage', value)
  },

  setApplicationVersion({ commit }, value) {
    commit('updateApplicationVersion', value)
  },
}

export default actions
